import { strings } from 'res';
import { DashboardIcon, InsightIcon, Megaphone, OrderIcon, ReportIcon, SLAIcon } from "../assets/svg/icon";
import { UserOutlined, DownCircleOutlined, NotificationOutlined, BookOutlined, SettingOutlined } from '@ant-design/icons';
import { reguler_access, admin, staff } from "../privileges/roles"

const dashBoardNavTree = [{
  key: "Home",
  path: strings.navigation.path.dashboard,
  title: "Home",
  breadcrumb: false,
  submenu: [
    {
      key: "Dashboard",
      path: strings.navigation.path.dashboard,
      title: "Dashboard",
      icon: DashboardIcon,
      access: reguler_access,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Pengumuman",
      path: strings.navigation.path.pengumuman,
      title: "Pengumuman",
      icon: NotificationOutlined,
      access: reguler_access,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Berita",
      path: strings.navigation.path.contents,
      title: "Berita",
      icon: ReportIcon,
      access: reguler_access,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Data Anggota",
      path: strings.navigation.path.layanan,
      title: "Data Anggota",
      icon: InsightIcon,
      access: reguler_access,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Keuangan",
      path: strings.navigation.path.layanan,
      title: "Keuangan",
      icon: BookOutlined,
      access: reguler_access,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Laporan",
      path: strings.navigation.path.unduhan,
      title: "Laporan",
      icon: DownCircleOutlined,
      breadcrumb: false,
      access: admin,
      submenu: []
    },
    // {
    //   key: "Pengumuman",
    //   path: strings.navigation.path.pengumuman,
    //   title: "Pengumuman",
    //   icon: Megaphone,
    //   access: reguler_access,
    //   breadcrumb: false,
    //   submenu: []
    // },
    {
      key: "Relawan",
      path: strings.navigation.path.kelurahan,
      title: "Relawan",
      icon: SLAIcon,
      access: admin,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Pengguna",
      path: strings.navigation.path.users,
      title: "Pengguna",
      icon: UserOutlined,
      access: staff,
      breadcrumb: false,
      submenu: []
    }
  ]
}]

const ControlTree = [{
  key: "Akun",
  path: strings.navigation.path.settings,
  title: "Akun",
  breadcrumb: false,
  submenu: [
    {
      key: "Pengaturan",
      path: strings.navigation.path.settings,
      title: "Pengaturan",
      icon: SettingOutlined,
      access: reguler_access,
      breadcrumb: false,
      submenu: []
    }
  ]
}]

const navigationConfig = [
  ...dashBoardNavTree,
  ...ControlTree,
]

export default navigationConfig;