import { Col, Row, message } from 'antd';
import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import StatisticWidget from 'components/shared-components/StatisticWidget';
import { useDispatch } from 'react-redux';
import { fetchAllCategory } from 'redux/features/category';
import { fetchAllContent } from 'redux/features/content';

export const DefaultDashboard = () => {

  const dispatch = useDispatch();
  const [categoryCount, setCategoryCount] = useState(0);
  const [contentCount, setContentCount] = useState(0);


  const getAllCategory = async () => {
    try {
      const response = await dispatch(fetchAllCategory()).unwrap()
      setCategoryCount(response.data.length)
    } catch (error) {
      message.error(error?.message || 'Failed to fetch data')
    }
  }

  const getAllContent = async (params) => {
    try {
      const response = await dispatch(fetchAllContent(params)).unwrap()
      setContentCount(response.data.length)
    } catch (error) {
      message.error(error?.message || 'Failed to fetch data')
    }
  }

  useEffect(() => {
    const filters = {
      page: 1,
      limit: 10,
      sort: "created_at"
    }

    getAllCategory();
    getAllContent(filters);
  }, [])

  return (
    <>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Dashboard</h2>
          <p>Jika menemukan bug ataupun error pada aplikasi ini bisa langsung</p>
        </Col>

      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} key={1}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Semua Berita`}
                value={13}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} key={1}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Dewan Terpilih`}
                value={87}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} key={2}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Semua Anggota`}
                value={345}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} key={2}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Pengeluaran Tahun 2024`}
                value={"Rp.570.390.500"}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} key={2}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Setoran Anggota`}
                value={"Rp.370.390.500"}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} key={2}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Jumlah Suara 2024`}
                value={1453778}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} key={2}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Jumlah Relawan`}
                value={10}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} key={2}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Dokumen`}
                value={10}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}


export default withRouter(DefaultDashboard);
